import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      component: () =>
        import(/* webpackChunkName: "rootNew" */ "@/view/layout/LayoutNew"),
      children: [
        {
          path: "/dashboard-warehouse",
          name: "dashboard.dashboardWarehouse",
          component: () =>
            import(
              /* webpackChunkName: "dashboardWarehouse" */ "@/own/pages/dashboard/Warehouse"
            ),
        },
        {
          path: "/dashboard-return",
          name: "dashboard.dashboardReturn",
          component: () =>
            import(
              /* webpackChunkName: "return" */ "@/own/pages/dashboard/ReturnNew"
            ),
        },
        {
          path: "/dashboard-delivery",
          name: "dashboard.dashboardDelivery",
          component: () =>
            import(
              /* webpackChunkName: "delivery" */ "@/own/pages/dashboard/Delivery"
            ),
        },

        // WES
        {
          path: "/wes-connectors",
          name: "wes.connectors",
          component: () =>
            import(
              /* webpackChunkName: "connectors" */ "@/own/pages/wes/Connectors"
            ),
        },
      ],
    },
    {
      path: "/",
      component: () =>
        import(/* webpackChunkName: "root" */ "@/view/layout/Layout"),
      children: [
        // menus start

        //fulfillment start
        {
          path: "/fulfillment/orders",
          name: "fulfillment.orders",
          component: () =>
            import(
              /* webpackChunkName: "orders" */ "@/own/pages/fulfillment/Orders"
            ),
        },
        {
          path: "/fulfillment/skus",
          name: "fulfillment.skus",
          component: () =>
            import(
              /* webpackChunkName: "skus" */ "@/own/pages/fulfillment/SKUs"
            ),
        },
        {
          path: "order_management/groups",
          name: "order_management.groups",
          component: () =>
            import(
              /* webpackChunkName: "skusgroup" */ "@/own/pages/orderManagement/SKUGroups"
            ),
        },
        {
          path: "/order_management/brands",
          name: "order_management.brands",
          component: () =>
            import(
              /* webpackChunkName: "skusbrands" */ "@/own/pages/orderManagement/Brands"
            ),
        },
        {
          path: "/fulfillment/inventory",
          name: "fulfillment.inventories",
          component: () =>
            import(
              /* webpackChunkName: "inventory" */ "@/own/pages/fulfillment/Inventory"
            ),
        },

        {
          path: "/fulfillment/suppliers",
          name: "fulfillment.suppliers",
          component: () =>
            import(
              /* webpackChunkName: "suppliers" */ "@/own/pages/fulfillment/Suppliers"
            ),
        },
        {
          path: "/fulfillment/products",
          name: "fulfillment.fulfillment_products",
          component: () =>
            import(
              /* webpackChunkName: "suppliers" */ "@/own/pages/fulfillment/Products"
            ),
        },
        // end of fulfillment
        {
          path: "/agings",
          name: "shipping.agings",
          component: () =>
            import(
              /* webpackChunkName: "aging" */ "@/own/pages/fulfillment/Aging"
            ),
        },
        {
          path: "/shipping/return_orders",
          name: "shipping.returns_management",
          component: () =>
            import(
              /* webpackChunkName: "returned" */ "@/own/pages/fulfillment/ReturnedNew"
            ),
        },
        {
          path: "/shipping/returns",
          name: "shipping.returns",
          component: () =>
            import(
              /* webpackChunkName: "returned" */ "@/own/pages/shipping/Returns.vue"
            ),
        },
        {
          path: "/uploads",
          name: "settings.uploads",
          component: () =>
            import(
              /* webpackChunkName: "uploads" */ "@/own/pages/fulfillment/FileUpload"
            ),
        },
        {
          path: "/smtp_settings",
          name: "settings.smtp_settings",
          component: () =>
            import(
              /* webpackChunkName: "uploads" */ "@/own/pages/settings/SMTPSettings"
            ),
        },
        {
          path: "/my_reports",
          name: "settings.reports",
          component: () =>
            import(
              /* webpackChunkName: "myreports" */ "@/own/pages/settings/MyReports"
            ),
        },

        // fulfillment end

        // warehouse management start

        {
          path: "/warehouses",
          name: "warehouse_management.warehouses",
          component: () =>
            import(
              /* webpackChunkName: "warehouses" */ "@/own/pages/warehouseManagement/Warehouses"
            ),
        },
        {
          path: "/main-configuration",
          name: "warehouse_management.main_configuration",
          component: () =>
            import(
              /* webpackChunkName: "main_configuration" */ "@/own/pages/warehouseManagement/MainConfiguration"
            ),
        },
        {
          path: "/scanning_configuration_templates",
          name: "warehouse_management.scanning_configuration_templates",
          component: () =>
            import(
              /* webpackChunkName: "scanning_configuration_templates" */ "@/own/pages/warehouseManagement/ScanningConfigurationTemplates"
            ),
        },
        {
          path: "/qc_checklists",
          name: "warehouse_management.qc_checklists",
          component: () =>
            import(
              /* webpackChunkName: "qc_checklists" */ "@/own/pages/warehouseManagement/QCChecklist"
            ),
        },
        {
          path: "/fulfillment_tasks",
          name: "warehouse_management.fulfillment_tasks",
          component: () =>
            import(
              /* webpackChunkName: "fulfillment_tasks" */ "@/own/pages/warehouseManagement/FulfillmentTasks"
            ),
        },
        {
          path: "/picking_assignment",
          name: "warehouse_management.picking_assignment",
          component: () =>
            import(
              /* webpackChunkName: "picking_assignment" */ "@/own/pages/warehouseManagement/PickingAssignment"
            ),
        },
        {
          path: "/asn",
          name: "warehouse_management.asn",
          component: () =>
            import(/* webpackChunkName: "asn" */ "@/own/pages/fulfillment/Asn"),
        },
        {
          path: "/qc",
          name: "warehouse_management.qc",
          component: () =>
            import(/* webpackChunkName: "qc" */ "@/own/pages/fulfillment/QC"),
        },
        {
          path: "/putaway_task",
          name: "warehouse_management.putaway_tasks",
          component: () =>
            import(
              /* webpackChunkName: "putaway_tasks" */ "@/own/pages/warehouseManagement/PutawayTasks"
            ),
        },
        {
          path: "/sortation_orders",
          name: "warehouse_management.sortation_orders",
          component: () =>
            import(
              /* webpackChunkName: "sortation_orders" */ "@/own/pages/warehouseManagement/SortationOrders"
            ),
        },
        {
          path: "/sortation_skus",
          name: "warehouse_management.sortation_skus",
          component: () =>
            import(
              /* webpackChunkName: "sortation_skus" */ "@/own/pages/warehouseManagement/SortationSKUs"
            ),
        },
        {
          path: "/putaway_rules",
          name: "warehouse_management.putaway_rules",
          component: () =>
            import(
              /* webpackChunkName: "putaway_rules" */ "@/own/pages/warehouseManagement/PutawayRules"
            ),
        },
        {
          path: "/waves",
          name: "warehouse_management.waves",
          component: () =>
            import(
              /* webpackChunkName: "waves" */ "@/own/pages/warehouseManagement/Waves"
            ),
        },
        {
          path: "/wave_logs",
          name: "warehouse_management.wave_logs",
          component: () =>
            import(
              /* webpackChunkName: "wave_logs" */ "@/own/pages/warehouseManagement/WaveLogs"
            ),
        },
        {
          path: "/receiving",
          name: "warehouse_management.receiving",
          component: () =>
            import(
              /* webpackChunkName: "receiving" */ "@/own/pages/warehouseManagement/Receiving"
            ),
        },
        {
          path: "/standard_receiving",
          name: "warehouse_management.standard_receiving",
          component: () =>
            import(
              /* webpackChunkName: "standard_receiving" */ "@/own/pages/warehouseManagement/StandardReceiving"
            ),
        },
        {
          path: "/inbound_packaging",
          name: "warehouse_management.inbound_packaging",
          component: () =>
            import(
              /* webpackChunkName: "inbound_packaging" */ "@/own/pages/warehouseManagement/InboundPackaging"
            ),
        },
        {
          path: "/standard_putaway",
          name: "warehouse_management.standard_putaway",
          component: () =>
            import(
              /* webpackChunkName: "standard_putaway" */ "@/own/pages/warehouseManagement/StandardPutaway"
            ),
        },
        {
          path: "/outbound/standard_order_picking",
          name: "warehouse_management.standard_order_picking",
          component: () =>
            import(
              /* webpackChunkName: "standard_order_picking" */ "@/own/pages/warehouseManagement/StandardOrderPicking"
            ),
        },
        {
          path: "/outbound/standard_order_packaging",
          name: "warehouse_management.standard_order_packaging",
          component: () =>
            import(
              /* webpackChunkName: "standard_order_packaging" */ "@/own/pages/warehouseManagement/StandardOrderPackaging.vue"
            ),
        },
        {
          path: "/outbound/standard_order_putaway",
          name: "warehouse_management.standard_order_putaway",
          component: () =>
            import(
              /* webpackChunkName: "standard_order_putaway" */ "@/own/pages/warehouseManagement/StandardOrderPutaway"
            ),
        },
        {
          path: "/outbound/standard_order_dispatch",
          name: "warehouse_management.standard_order_dispatch",
          component: () =>
            import(
              /* webpackChunkName: "standard_order_dispatch" */ "@/own/pages/warehouseManagement/StandardOrderDispatch"
            ),
        },
        {
          path: "/picking",
          name: "warehouse_management.picking",
          component: () =>
            import(
              /* webpackChunkName: "picking" */ "@/own/pages/warehouseManagement/Picking2"
            ),
        },
        {
          path: "/packing",
          name: "warehouse_management.packing",
          component: () =>
            import(
              /* webpackChunkName: "packing" */ "@/own/pages/warehouseManagement/Packing2"
            ),
        },

        {
          path: "/shipping",
          name: "warehouse_management.shipping",
          component: () =>
            import(
              /* webpackChunkName: "packing" */ "@/own/pages/warehouseManagement/Shipping"
            ),
        },
        {
          path: "/customer_cases",
          name: "warehouse_management.customer_cases",
          component: () =>
            import(
              /* webpackChunkName: "customerCases" */ "@/own/pages/warehouseManagement/CustomerCases"
            ),
        },
        {
          path: "/sortation_sku_cases",
          name: "warehouse_management.sortation_sku_cases",
          component: () =>
            import(
              /* webpackChunkName: "sortation_sku_cases" */ "@/own/pages/warehouseManagement/SortationSKUCases"
            ),
        },
        {
          path: "/b2b_pick_and_pack",
          name: "warehouse_management.b2b_pick_and_pack",
          component: () =>
            import(
              /* webpackChunkName: "packing" */ "@/own/pages/warehouseManagement/B2BPickAndPack"
            ),
        },
        {
          path: "/skipped_fulfillment_tasks",
          name: "warehouse_management.skipped_fulfillment_tasks",
          component: () =>
            import(
              /* webpackChunkName: "skipped_fulfillment_tasks" */ "@/own/pages/warehouseManagement/SkippedFulfillmentTasks"
            ),
        },
        {
          path: "/putaway_rule_based_templates",
          name: "warehouse_management.putaway_rule_based_templates",
          component: () =>
            import(
              /* webpackChunkName: "putaway_rule_based_template" */ "@/own/pages/warehouseManagement/PutawayRuleBasedTemplate"
            ),
        },
        {
          path: "/allocation_rules",
          name: "warehouse_management.allocation_rules",
          component: () =>
            import(
              /* webpackChunkName: "allocation_rules" */ "@/own/pages/warehouseManagement/AllocationRules"
            ),
        },
        {
          path: "/parsing_rules",
          name: "warehouse_management.parsing_rules",
          component: () =>
            import(
              /* webpackChunkName: "parsing_rules" */ "@/own/pages/warehouseManagement/DataParsing"
            ),
        },
        {
          path: "/allocation_rule_based_templates",
          name: "warehouse_management.allocation_rule_based_templates",
          component: () =>
            import(
              /* webpackChunkName: "allocation_rule_based_template" */ "@/own/pages/warehouseManagement/AllocationRuleBasedTemplate"
            ),
        },
        {
          path: "/allocation_strategy_rules",
          name: "warehouse_management.allocation_strategy_rules",
          component: () =>
            import(
              /* webpackChunkName: "allocation_rule_based_template" */ "@/own/pages/warehouseManagement/AllocationStrategyRules"
            ),
        },
        {
          path: "/picking_strategy_rules",
          name: "warehouse_management.picking_strategy_rules",
          component: () =>
            import(
              /* webpackChunkName: "picking_strategy_rules" */ "@/own/pages/warehouseManagement/PickingStrategyRules"
            ),
        },
        {
          path: "/warehouse_management/transaction_logs",
          name: "warehouse_management.transaction_logs",
          component: () =>
            import(
              /* webpackChunkName: "transaction_logs" */ "@/own/pages/warehouseManagement/TransactionLogs"
            ),
        },
        {
          path: "/warehouse_management/inventory_balances",
          name: "warehouse_management.inventory_balances",
          component: () =>
            import(
              /* webpackChunkName: "inventory_balances" */ "@/own/pages/warehouseManagement/InventoryBalance"
            ),
        },
        {
          path: "/warehouse_management/inventory_overview",
          name: "warehouse_management.inventory_overview",
          component: () =>
            import(
              /* webpackChunkName: "inventory_overview" */ "@/own/pages/warehouseManagement/InventoryOverview"
            ),
        },
        {
          path: "/warehouse_management/inventory_adjustments",
          name: "warehouse_management.inventory_adjustments",
          component: () =>
            import(
              /* webpackChunkName: "inventory_adjustment" */ "@/own/pages/warehouseManagement/InventoryAdjustment"
            ),
        },
        {
          path: "/warehouse_management/location_movements",
          name: "warehouse_management.location_movements",
          component: () =>
            import(
              /* webpackChunkName: "location_movement" */ "@/own/pages/warehouseManagement/LocationMovement"
            ),
        },
        {
          path: "/warehouse_management/random_cycle_counts",
          name: "warehouse_management.random_cycle_counts",
          component: () =>
            import(
              /* webpackChunkName: "random_cycle_count" */ "@/own/pages/warehouseManagement/RandomCycleCount"
            ),
        },
        {
          path: "/warehouse_management/standard_cycle_counts",
          name: "warehouse_management.standard_cycle_counts",
          component: () =>
            import(
              /* webpackChunkName: "standard_cycle_count" */ "@/own/pages/warehouseManagement/StandardCycleCount"
            ),
        },
        {
          path: "/warehouse_management/inventory/cycle_counts",
          name: "warehouse_management.cycle_counts",
          component: () =>
            import(
              /* webpackChunkName: "cycle_counts" */ "@/own/pages/warehouseManagement/CycleCounts"
            ),
        },
        {
          path: "/warehouse_management/package_movements",
          name: "warehouse_management.package_movements",
          component: () =>
            import(
              /* webpackChunkName: "warehouse_management.package_movements" */ "@/own/pages/warehouseManagement/StandardPackageMovement"
            ),
        },
        {
          path: "/warehouse_management/standard_quality_checks",
          name: "warehouse_management.standard_quality_checks",
          component: () =>
            import(
              /* webpackChunkName: "standard_quality_checks" */ "@/own/pages/warehouseManagement/StandardQualityCheck"
            ),
        },
        {
          path: "/warehouse_management/sku_movements",
          name: "warehouse_management.sku_movements",
          component: () =>
            import(
              /* webpackChunkName: "warehouse_management.sku_movements" */ "@/own/pages/warehouseManagement/SKUMovement"
            ),
        },
        {
          path: "/warehouse_management/inventory_snapshots",
          name: "warehouse_management.inventory_snapshots",
          component: () =>
            import(
              /* webpackChunkName: "inventory_snapshots" */ "@/own/pages/warehouseManagement/InventorySnapShots"
            ),
        },
        {
          path: "/warehouse_management/warehouse_basic",
          name: "warehouse_management.warehouse_basic",
          component: () =>
            import(
              /* webpackChunkName: "warehouse_basic" */ "@/own/pages/warehouseManagement/WarehouseBasic"
            ),
        },
        {
          path: "/warehouse_management/stores",
          name: "warehouse_management.client_stores",
          component: () =>
            import(
              /* webpackChunkName: "client_stores" */ "@/own/pages/warehouseManagement/Store"
            ),
        },
        {
          path: "/warehouse_management/uom_conversions",
          name: "warehouse_management.uom_conversions",
          component: () =>
            import(
              /* webpackChunkName: "warehouse_basic" */ "@/own/pages/warehouseManagement/UOMConversions"
            ),
        },
        {
          path: "/warehouse_management/wms_app_apks",
          name: "warehouse_management.wms_app_apks",
          component: () =>
            import(
              /* webpackChunkName: "wms_app_apks" */ "@/own/pages/warehouseManagement/WMSAppApks.vue"
            ),
        },
        {
          path: "/warehouse_management/rco_picking_lists",
          name: "warehouse_management.rco_picking_lists",
          component: () =>
            import(
              /* webpackChunkName: "rco_picking_lists" */ "@/own/pages/warehouseManagement/RCOPickingList.vue"
            ),
        },
        {
          path: "/warehouse_management/standard_grn",
          name: "warehouse_management.standard_grn",
          component: () =>
            import(
              /* webpackChunkName: "standard_grn" */ "@/own/pages/warehouseManagement/StandardGrn.vue"
            ),
        },
        {
          path: "warehouse_management/package_codes",
          name: "warehouse_management.package_codes",
          component: () =>
            import(
              /* webpackChunkName: "package_codes" */ "@/own/pages/warehouseManagement/PackageCode.vue"
            ),
        },
        // warehouse management end

        // order management
        {
          path: "/pending_audit_orders",
          name: "order_management.pending_audit_orders",
          component: () =>
            import(
              /* webpackChunkName: "pending_audit_orders" */ "@/own/pages/orderManagement/PendingAuditOrders.vue"
            ),
        },
        {
          path: "/pending_confirmation_orders",
          name: "order_management.pending_confirmation_orders",
          component: () =>
            import(
              /* webpackChunkName: "pending_confirmation" */ "@/own/pages/orderManagement/PendingConfirmationOrders.vue"
            ),
        },
        {
          path: "/subscriptions",
          name: "order_management.subscriptions",
          component: () =>
            import(
              /* webpackChunkName: "subscriptions" */ "@/own/pages/orderManagement/SubscriptionManagement.vue"
            ),
        },
        {
          path: "/subscription_buyers",
          name: "order_management.subscription_buyers",
          component: () =>
            import(
              /* webpackChunkName: "subscription_buyers" */ "@/own/pages/orderManagement/SubscriptionBuyers.vue"
            ),
        },
        {
          path: "/gift_cards",
          name: "order_management.gift_cards",
          component: () =>
            import(
              /* webpackChunkName: "subscription_buyers" */ "@/own/pages/orderManagement/GiftCards.vue"
            ),
        },
        {
          path: "/bundles",
          name: "order_management.bundles",
          component: () =>
            import(
              /* webpackChunkName: "bundles" */ "@/own/pages/fulfillment/Bundles"
            ),
        },
        {
          path: "/order_management_rules",
          name: "order_management.order_management_rules",
          component: () =>
            import(
              /* webpackChunkName: "order_management_rules" */ "@/own/pages/orderManagement/OMSRules"
            ),
        },

        // organizations start

        {
          path: "/organizations",
          name: "organization.organizations",
          component: () =>
            import(
              /* webpackChunkName: "organizations" */ "@/own/pages/organization/Organizations"
            ),
        },
        {
          path: "/organization/organization_address_books",
          name: "organization.organization_address_books",
          component: () =>
            import(
              /* webpackChunkName: "organizationsAddressBook" */ "@/own/pages/organization/OrganizationsAddressBook"
            ),
        },
        {
          path: "/bankAccounts",
          name: "organization.bankAccounts",
          component: () =>
            import(
              /* webpackChunkName: "bankAccounts" */ "@/own/pages/organization/BankAccounts"
            ),
        },
        {
          path: "/dispatchVehicle",
          name: "organization.dispatchVehicle",
          component: () =>
            import(
              /* webpackChunkName: "bankAccounts" */ "@/own/pages/organization/DispatchVehicle"
            ),
        },
        {
          path: "/addresses",
          name: "organization.addresses",
          component: () =>
            import(
              /* webpackChunkName: "addresses" */ "@/own/pages/organization/Addresses"
            ),
        },
        {
          path: "/systemCredentials",
          name: "organization.systemCredentials",
          component: () =>
            import(
              /* webpackChunkName: "systemCredentials" */ "@/own/pages/organization/SystemCredentials"
            ),
        },
        {
          path: "/organizationActionFlow",
          name: "organization.organizationActionFlow",
          component: () =>
            import(
              /* webpackChunkName: "organizationActionFlow" */ "@/own/pages/organization/OrganizationActionFlow"
            ),
        },
        {
          path: "/divisions",
          name: "organization.divisions",
          component: () =>
            import(
              /* webpackChunkName: "divisions" */ "@/own/pages/organization/Divisions"
            ),
        },
        {
          path: "/organization/reason_codes",
          name: "organization.reason_codes",
          component: () =>
            import(
              /* webpackChunkName: "organizationsAddressBook" */ "@/own/pages/organization/ReasonCode"
            ),
        },
        {
          path: "/uom_and_package_types",
          name: "organization.uom_and_package_types",
          component: () =>
            import(
              /* webpackChunkName: "uom_and_package_types" */ "@/own/pages/organization/UOMAndPackageTypes"
            ),
        },

        // organizations end

        {
          path: "/shipping_returns_extensions",
          name: "shipping.returns_extensions",
          component: () =>
            import(
              /* webpackChunkName: "clients" */ "@/own/pages/integrations/ReturnIntegrations.vue"
            ),
        },
        {
          path: "/shipping_returns_client_api",
          name: "shipping.returns_client_api",
          component: () =>
            import(
              /* webpackChunkName: "returnClientAPI" */ "@/own/pages/integrations/ReturnClientAPIDoc.vue"
            ),
        },
        {
          path: "/dashboard-analytics",
          name: "dashboard.dashboardAnalytics",
          component: () =>
            import(
              /* webpackChunkName: "analitics" */ "@/own/pages/dashboard/Analitics"
            ),
        },
        {
          path: "/profile",
          name: "profile",
          component: () =>
            import(
              /* webpackChunkName: "profile" */ "@/own/pages/profile/Profile2"
            ),
        },
        //dashboard end

        //authorization start
        {
          path: "/clients",
          name: "authorization.clients",
          component: () =>
            import(
              /* webpackChunkName: "clients" */ "@/own/pages/customers/Clients"
            ),
        },
        {
          path: "/seller_addresses",
          name: "authorization.seller_addresses",
          component: () =>
            import(
              /* webpackChunkName: "SellerAddresses" */ "@/own/pages/accounts/SellerAddresses"
            ),
        },
        {
          path: "/apps",
          name: "authorization.apps",
          component: () =>
            import(/* webpackChunkName: "apps" */ "@/own/pages/customers/Apps"),
        },
        {
          path: "/roles",
          name: "authorization.roles",
          component: () =>
            import(
              /* webpackChunkName: "roles" */ "@/own/pages/accounts/Roles"
            ),
        },
        {
          path: "/accounts",
          name: "authorization.accounts",
          component: () =>
            import(
              /* webpackChunkName: "accounts" */ "@/own/pages/accounts/Accounts"
            ),
        },
        {
          path: "/users",
          name: "authorization.users",
          component: () =>
            import(
              /* webpackChunkName: "users" */ "@/own/pages/accounts/Users"
            ),
        },
        {
          path: "/carrier_accounts",
          name: "authorization.carrier_accounts",
          component: () =>
            import(
              /* webpackChunkName: "carrier_accounts" */ "@/own/pages/accounts/CarrierAccounts"
            ),
        },
        {
          path: "/user_types",
          name: "authorization.user_types",
          component: () =>
            import(
              /* webpackChunkName: "users" */ "@/own/pages/accounts/UserTypes"
            ),
        },
        //authorization end

        {
          path: "/platforms",
          name: "datahub.platforms",
          component: () =>
            import(
              /* webpackChunkName: "platforms" */ "@/own/pages/datahub/Platforms"
            ),
        },
        {
          path: "/messages",
          name: "datahub.messages",
          component: () =>
            import(
              /* webpackChunkName: "messages" */ "@/own/pages/datahub/Messages"
            ),
        },
        {
          path: "/integrations",
          name: "datahub.integrations",
          component: () =>
            import(
              /* webpackChunkName: "integrations" */ "@/own/pages/datahub/Integrations"
            ),
        },
        {
          path: "/webhooks",
          name: "datahub.webhooks",
          component: () =>
            import(
              /* webpackChunkName: "webhooks" */ "@/own/pages/datahub/Webhooks"
            ),
        },
        {
          path: "/webhooks_logs",
          name: "datahub.webhooks_logs",
          component: () =>
            import(
              /* webpackChunkName: "webhooks_logs" */ "@/own/pages/datahub/WebhooksLogs"
            ),
        },
        {
          path: "/transactions",
          name: "order_management.messagesNew",
          component: () =>
            import(
              /* webpackChunkName: "messages" */ "@/own/pages/datahub/Messages2"
            ),
        },
        {
          path: "/onlineStores",
          name: "order_management.integrationsNew",
          component: () =>
            import(
              /* webpackChunkName: "integrations" */ "@/own/pages/datahub/Integrations2"
            ),
        },

        // settings
        {
          path: "/datahub",
          name: "settings.datahub",
          component: () =>
            import(
              /* webpackChunkName: "datahub" */ "@/own/pages/settings/Datahub"
            ),
        },
        {
          path: "/settings/dynamic_documents",
          name: "settings.dynamic_documents",
          component: () =>
            import(
              /* webpackChunkName: "DynamicDoc" */ "@/own/pages/settings/DynamicDoc"
            ),
        },
        {
          path: "/settings/dynamic_reports",
          name: "settings.dynamic_reports",
          component: () =>
            import(
              /* webpackChunkName: "DynamicReport" */ "@/own/pages/settings/DynamicReport"
            ),
        },
        {
          path: "/emails",
          name: "settings.emails",
          component: () =>
            import(
              /* webpackChunkName: "emails" */ "@/own/pages/settings/Emails"
            ),
        },
        {
          path: "/mailtemp",
          name: "settings.mailTemplate",
          component: () =>
            import(
              /* webpackChunkName: "mailtemp" */ "@/own/pages/settings/MailTemplate"
            ),
        },
        {
          path: "/service",
          name: "settings.services",
          component: () =>
            import(
              /* webpackChunkName: "service" */ "@/own/pages/settings/Service"
            ),
        },
        {
          path: "/translations",
          name: "settings.translations",
          component: () =>
            import(
              /* webpackChunkName: "service" */ "@/own/pages/settings/Translation"
            ),
        },
        {
          path: "/settings/email_reports",
          name: "settings.email_reports",
          component: () =>
            import(
              /* webpackChunkName: "email_reports" */ "@/own/pages/settings/EmailReports.vue"
            ),
        },

        // finance
        {
          path: "/cod",
          name: "cod.cod",
          component: () =>
            import(/* webpackChunkName: "cod" */ "@/own/pages/finance/COD"),
        },
        {
          path: "/financeServices",
          name: "cod.finance_services",
          component: () =>
            import(
              /* webpackChunkName: "FinServices" */ "@/own/pages/finance/FinServices"
            ),
        },
        {
          path: "/currencies",
          name: "cod.currencies",
          component: () =>
            import(
              /* webpackChunkName: "FinServices" */ "@/own/pages/finance/CurrencyManagements"
            ),
        },
        {
          path: "/contracts",
          name: "cod.contracts",
          component: () =>
            import(
              /* webpackChunkName: "FinCustomer" */ "@/own/pages/finance/FinCustomer"
            ),
        },
        {
          path: "/invoices",
          name: "cod.invoices",
          component: () =>
            import(
              /* webpackChunkName: "Invoices" */ "@/own/pages/finance/Invoices"
            ),
        },
        {
          path: "/payment_types",
          name: "cod.payment_types",
          component: () =>
            import(
              /* webpackChunkName: "paymentType" */ "@/own/pages/finance/PaymentType"
            ),
        },
        {
          path: "/exchange_management",
          name: "cod.exchange_management",
          component: () =>
            import(
              /* webpackChunkName: "ExchangeManagements" */ "@/own/pages/finance/ExchangeManagements"
            ),
        },
        // store
        {
          path: "/stores",
          name: "store.stores",
          component: () =>
            import(/* webpackChunkName: "store" */ "@/own/pages/stores/Store"),
        },
        {
          path: "/store/contacts",
          name: "store.contacts",
          component: () =>
            import(
              /* webpackChunkName: "contacts" */ "@/own/pages/stores/Contacts"
            ),
        },
        {
          path: "/store/custom",
          name: "store.pages",
          component: () =>
            import(
              /* webpackChunkName: "custom" */ "@/own/pages/stores/Custom"
            ),
        },
        {
          path: "/store/sliders",
          name: "store.sliders",
          component: () =>
            import(
              /* webpackChunkName: "sliders" */ "@/own/pages/stores/Sliders"
            ),
        },
        {
          path: "/store/categories",
          name: "store.categories",
          component: () =>
            import(
              /* webpackChunkName: "categories" */ "@/own/pages/stores/Categories"
            ),
        },
        {
          path: "/store/products",
          name: "store.products",
          component: () =>
            import(
              /* webpackChunkName: "products" */ "@/own/pages/stores/Products"
            ),
        },
        {
          path: "/store/buyers",
          name: "store.buyers",
          component: () =>
            import(
              /* webpackChunkName: "buyers" */ "@/own/pages/stores/Buyers"
            ),
        },
        {
          path: "/reviews",
          name: "store.reviews",
          component: () =>
            import(
              /* webpackChunkName: "store_reviews" */ "@/own/pages/stores/Reviews"
            ),
        },
        {
          path: "/testimonials",
          name: "store.testimonials",
          component: () =>
            import(
              /* webpackChunkName: "store_testimonials" */ "@/own/pages/stores/Testimonials"
            ),
        },
        {
          path: "/coupons",
          name: "store.coupons",
          component: () =>
            import(
              /* webpackChunkName: "store_coupons" */ "@/own/pages/stores/Coupons"
            ),
        },
        {
          path: "/payment",
          name: "store.payments",
          component: () =>
            import(
              /* webpackChunkName: "store_payments" */ "@/own/pages/stores/Payments"
            ),
        },
        {
          path: "/discovers",
          name: "store.discovers",
          component: () =>
            import(
              /* webpackChunkName: "store_discovers" */ "@/own/pages/stores/Discovers"
            ),
        },
        {
          path: "/page-builder",
          name: "store.pageBuilder",
          component: () =>
            import(
              /* webpackChunkName: "store_pageBuilder" */ "@/own/pages/stores/PageBuilder"
            ),
        },
        // storeNew
        {
          path: "/storeNew/productsNew",
          name: "storeNew.productsNew",
          component: () =>
            import(
              /* webpackChunkName: "products" */ "@/own/pages/storeNew/Products"
            ),
        },
        // shipping
        {
          path: "/shipping/couriers",
          name: "shipping.couriers",
          component: () =>
            import(
              /* webpackChunkName: "couriers" */ "@/own/pages/shipping/Couriers"
            ),
        },
        {
          path: "/shipping/courier_status",
          name: "shipping.courierStatus",
          component: () =>
            import(
              /* webpackChunkName: "courier_status" */ "@/own/pages/shipping/CourierStatus"
            ),
        },
        {
          path: "/shipping/courier_accounts",
          name: "shipping.courierAccounts",
          component: () =>
            import(
              /* webpackChunkName: "courier_accounts" */ "@/own/pages/shipping/CourierAccounts"
            ),
        },
        {
          path: "/shipping/order_status",
          name: "shipping.orderStatus",
          component: () =>
            import(
              /* webpackChunkName: "order_status" */ "@/own/pages/shipping/OrderStatus"
            ),
        },
        {
          path: "/shipping/trackingPage",
          name: "shipping.trackingPage",
          component: () =>
            import(
              /* webpackChunkName: "trackingPage" */ "@/own/pages/shipping/TrackingPage"
            ),
        },
        {
          path: "/shipping/countries",
          name: "shipping.countries",
          component: () =>
            import(
              /* webpackChunkName: "countries" */ "@/own/pages/shipping/Countries"
            ),
        },
        {
          path: "/shipping/regions",
          name: "shipping.regions",
          component: () =>
            import(
              /* webpackChunkName: "ShippingRegions" */ "@/own/pages/shipping/Regions"
            ),
        },
        {
          path: "/shipping/end_customer_notifications",
          name: "shipping.end_customer_notifications",
          component: () =>
            import(
              /* webpackChunkName: "CustomerNotifications" */ "@/own/pages/shipping/CustomerNotifications"
            ),
        },
        {
          path: "/shipping/pickup_orders",
          name: "shipping.pickup_orders",
          component: () =>
            import(
              /* webpackChunkName: "PickupOrders" */ "@/own/pages/shipping/PickupOrders"
            ),
        },
        {
          path: "/shipping/courier_location_mappings",
          name: "shipping.courier_location_mappings",
          component: () =>
            import(
              /* webpackChunkName: "couriers" */ "@/own/pages/shipping/CourierAreaListMapping"
            ),
        },
        {
          path: "/shipping/location_mappings",
          name: "shipping.location_mappings",
          component: () =>
            import(
              /* webpackChunkName: "location_mappings" */ "@/own/pages/shipping/LocationMapping"
            ),
        },
        {
          path: "/shipping/courier_assignment_rules",
          name: "shipping.courier_assignment_rules",
          component: () =>
            import(
              /* webpackChunkName: "couriers" */ "@/own/pages/shipping/CourierAssignRules"
            ),
        },
        {
          path: "/shipping/courier_assignment_rule_logs",
          name: "shipping.courier_assignment_rule_logs",
          component: () =>
            import(
              /* webpackChunkName: "courierRuleLog" */ "@/own/pages/shipping/CourierRuleLogs"
            ),
        },
        {
          path: "/shipping/carrier_shipment_api_request_logs",
          name: "shipping.carrier_shipment_api_request_logs",
          component: () =>
            import(
              /* webpackChunkName: "CarrierShipmentApiRequestLogs" */ "@/own/pages/shipping/CarrierShipmentApiRequestLogs"
            ),
        },
        // datasync
        {
          path: "/engineMonitor",
          name: "engine.engineMonitor",
          component: () =>
            import(
              /* webpackChunkName: "engineMonitor" */ "@/own/pages/datasync/Monitor"
            ),
        },
        {
          path: "/engineJobs",
          name: "engine.engineJobs",
          component: () =>
            import(
              /* webpackChunkName: "enginejobs" */ "@/own/pages/datasync/Jobs"
            ),
        },
        {
          path: "/engineScheduler",
          name: "engine.engineScheduler",
          component: () =>
            import(
              /* webpackChunkName: "enginescheduler" */ "@/own/pages/datasync/Scheduler"
            ),
        },
        // report
        {
          path: "/reports/reportPendingSkuSortation",
          name: "reports.reportPendingSkuSortation",
          component: () =>
            import(
              /* webpackChunkName: "reportPendingSkuSortation" */ "@/own/pages/reports/SKUPendingSortation"
            ),
        },
        {
          path: "/reports/deliveryPerformanceCountry",
          name: "reports.reportDeliveryPerformanceCountryWise",
          component: () =>
            import(
              /* webpackChunkName: "deliveryPerformanceCountry" */ "@/own/pages/reports/DeliveryPerformanceCountryWise"
            ),
        },
        {
          path: "/reports/salesWonCustomerPerMonth",
          name: "reports.reportSalesWonCustomerPerMonth",
          component: () =>
            import(
              /* webpackChunkName: "salesWonCustomerPerMonth" */ "@/own/pages/reports/WonCustomerPerMonth"
            ),
        },
        {
          path: "/reports/salesCumulativeCustomerWonPerMonth",
          name: "reports.reportSalesCumulativeCustomerWonPerMonth",
          component: () =>
            import(
              /* webpackChunkName: "CumulativeCustomersWonPerMonth" */ "@/own/pages/reports/CumulativeCustomersWonPerMonth"
            ),
        },
        {
          path: "/reports/salesUniqueActiveCustomers",
          name: "reports.reportSalesUniqueActiveCustomers",
          component: () =>
            import(
              /* webpackChunkName: "UniqueActiveCustomersPerMonth" */ "@/own/pages/reports/UniqueActiveCustomersPerMonth"
            ),
        },
        {
          path: "/reports/reportFulfilledVsDelivered",
          name: "reports.reportFulfilledVsDelivered",
          component: () =>
            import(
              /* webpackChunkName: "FulfilledvsDelivered" */ "@/own/pages/reports/FulfilledvsDelivered"
            ),
        },
        {
          path: "/reports/reportFulfilledVsShipped",
          name: "reports.reportFulfilledVsShipped",
          component: () =>
            import(
              /* webpackChunkName: "ReportFulfilledVsShipped" */ "@/own/pages/reports/ReportFulfilledVsShipped"
            ),
        },
        {
          path: "/reports/reportSalesOrders",
          name: "reports.reportSalesOrders",
          component: () =>
            import(
              /* webpackChunkName: "reportSalesOrders" */ "@/own/pages/reports/ReportSalesOrders"
            ),
        },
        {
          path: "/reports/reportClientsMovements",
          name: "reports.reportClientsMovements",
          component: () =>
            import(
              /* webpackChunkName: "reportClientsMovements" */ "@/own/pages/reports/ClientsMovements"
            ),
        },
        {
          path: "/reports/reportShippedByCustomer",
          name: "reports.reportShippedByCustomer",
          component: () =>
            import(
              /* webpackChunkName: "reportShippedByCustomer" */ "@/own/pages/reports/ReportShippedByCustomer"
            ),
        },
        {
          path: "/reports/reportShippedByCarrier",
          name: "reports.reportShippedByCarrier",
          component: () =>
            import(
              /* webpackChunkName: "reportShippedByCarrier" */ "@/own/pages/reports/ReportShippedByCarrier"
            ),
        },
        {
          path: "/reports/reportCallAgent",
          name: "reports.reportCallAgent",
          component: () =>
            import(
              /* webpackChunkName: "reportCallAgent" */ "@/own/pages/reports/ReportCallAgent"
            ),
        },
        {
          path: "/reports/reportMonthlyAverageOrders",
          name: "reports.reportMonthlyAverageOrders",
          component: () =>
            import(
              /* webpackChunkName: "reportMonthlyAverageOrders" */ "@/own/pages/reports/ReportMonthlyAverageOrders"
            ),
        },
        {
          path: "/reports/reportOrderCycle",
          name: "reports.reportOrderCycle",
          component: () =>
            import(
              /* webpackChunkName: "reportOrderCycle" */ "@/own/pages/reports/ReportOrderCycle"
            ),
        },
        {
          path: "/reports/matrixAttemptsDelivered",
          name: "reports.matrixAttemptsDelivered",
          component: () =>
            import(
              /* webpackChunkName: "matrixAttemptsDelivered" */ "@/own/pages/reports/ReportMatrixAttemptsDelivered"
            ),
        },
        {
          path: "/reports/lastMileForwardLeg",
          name: "reports.lastMileForwardLeg",
          component: () =>
            import(
              /* webpackChunkName: "lastMileForwardLeg" */ "@/own/pages/reports/LastMileForwardLeg"
            ),
        },
        {
          path: "/reports/reportOrderReceivingTimeHeatmap",
          name: "reports.reportOrderReceivingTimeHeatmap",
          component: () =>
            import(
              /* webpackChunkName: "reportOrderReceivingTime" */ "@/own/pages/reports/ReportOrderReceivingTime"
            ),
        },
        {
          path: "/reports/reportPortalUsageTimeHeatmap",
          name: "reports.reportPortalUsageTimeHeatmap",
          component: () =>
            import(
              /* webpackChunkName: "reportPortalUsersTime" */ "@/own/pages/reports/reportPortalUsersTime"
            ),
        },
        {
          path: "/reports/reverseLogistics",
          name: "reports.reverseLogistics",
          component: () =>
            import(
              /* webpackChunkName: "reportReverseLogistics" */ "@/own/pages/reports/ReportReverseLogistics"
            ),
        },
        {
          path: "/reports/reportSkuMovement",
          name: "reports.reportSkuMovement",
          component: () =>
            import(
              /* webpackChunkName: "reportSkuMovement" */ "@/own/pages/reports/ReportSkuMovement"
            ),
        },
        {
          path: "/reports/orderCount",
          name: "reports.orderCount",
          component: () =>
            import(
              /* webpackChunkName: "reportOrderCount" */ "@/own/pages/reports/ReportOrderCount"
            ),
        },
        {
          path: "/reports/deliveryEstimatedAccuracy",
          name: "reports.deliveryEstimatedAccuracy",
          component: () =>
            import(
              /* webpackChunkName: "deliveryEstimatedAccuracy" */ "@/own/pages/reports/DeliveryEstimatedAccuracy"
            ),
        },
        {
          path: "/reports/reportBinOccupancy",
          name: "reports.reportBinOccupancy",
          component: () =>
            import(
              /* webpackChunkName: "binOccupancy" */ "@/own/pages/reports/BinOccupancy.vue"
            ),
        },
        {
          path: "/reports/reportItemHistory",
          name: "reports.reportItemHistory",
          component: () =>
            import(
              /* webpackChunkName: "itemHistory" */ "@/own/pages/reports/ItemHistory.vue"
            ),
        },
        {
          path: "/reports/asnReport",
          name: "reports.asnReport",
          component: () =>
            import(
              /* webpackChunkName: "asnreport" */ "@/own/pages/reports/Asn.vue"
            ),
        },
        {
          path: "/reports/reportPutawayDetails",
          name: "reports.reportPutawayDetails",
          component: () =>
            import(
              /* webpackChunkName: "PutawayDetails" */ "@/own/pages/reports/PutawayDetails.vue"
            ),
        },
        {
          path: "/reports/reportPutawaySummary",
          name: "reports.reportPutawaySummary",
          component: () =>
            import(
              /* webpackChunkName: "PutawaySummary" */ "@/own/pages/reports/PutawaySummary.vue"
            ),
        },
        {
          path: "/reports/reportUserProductivity",
          name: "reports.reportUserProductivity",
          component: () =>
            import(
              /* webpackChunkName: "userProductivity" */ "@/own/pages/reports/UserProductivity.vue"
            ),
        },
        {
          path: "/reports/reportUserDetailProductivity",
          name: "reports.reportUserDetailProductivity",
          component: () =>
            import(
              /* webpackChunkName: "reportUserDetailProductivity" */ "@/own/pages/reports/UserDetailProductivity.vue"
            ),
        },
        {
          path: "/reports/reportStockDetailReport",
          name: "reports.reportStockDetailReport",
          component: () =>
            import(
              /* webpackChunkName: "reportStockDetailReport" */ "@/own/pages/reports/StockDetail.vue"
            ),
        },
        {
          path: "/reports/reportStockSummaryReport",
          name: "reports.reportStockSummaryReport",
          component: () =>
            import(
              /* webpackChunkName: "reportStockSummaryReport" */ "@/own/pages/reports/StockSummary.vue"
            ),
        },
        {
          path: "/reports/reportCartonMovement",
          name: "reports.reportCartonMovement",
          component: () =>
            import(
              /* webpackChunkName: "reportCartonMovement" */ "@/own/pages/reports/CartonMovement.vue"
            ),
        },
        {
          path: "/reports/reportOrderSummaryReport",
          name: "reports.reportOrderSummaryReport",
          component: () =>
            import(
              /* webpackChunkName: "OrderSummary" */ "@/own/pages/reports/OrderSummary.vue"
            ),
        },
        {
          path: "/reports/reportOrderDetailReport",
          name: "reports.reportOrderDetailReport",
          component: () =>
            import(
              /* webpackChunkName: "OrderDetail" */ "@/own/pages/reports/OrderDetail.vue"
            ),
        },
        {
          path: "/reports/reportItemHistoryDetail",
          name: "reports.reportItemHistoryDetail",
          component: () =>
            import(
              /* webpackChunkName: "reportItemHistoryDetail" */ "@/own/pages/reports/ItemHistoryDetail.vue"
            ),
        },
        {
          path: "/reports/reportOrderDispatchReport",
          name: "reports.reportOrderDispatchReport",
          component: () =>
            import(
              /* webpackChunkName: "reportOrderDispatchReport" */ "@/own/pages/reports/OrderDispatch.vue"
            ),
        },
        {
          path: "/reports/reportSortation",
          name: "reports.reportSortation",
          component: () =>
            import(
              /* webpackChunkName: "reportSortation" */ "@/own/pages/reports/Sortation.vue"
            ),
        },
        {
          path: "reports/reportDailyStockReport",
          name: "reports.reportDailyStockReport",
          component: () =>
            import(
              /* webpackChunkName: "reportDailyStockReport" */ "@/own/pages/reports/DailyStock.vue"
            ),
        },
        {
          path: "/reports/PackageMovement",
          name: "reports.reportPackageMovement",
          component: () =>
            import(
              /* webpackChunkName: "reportPackageMovement" */ "@/own/pages/reports/PackageMovement.vue"
            ),
        },
        {
          path: "/reports/reportGrnCaseWise",
          name: "reports.reportGrnCaseWise",
          component: () =>
            import(
              /* webpackChunkName: "reportGrnCaseWise" */ "@/own/pages/reports/GrnCaseWise.vue"
            ),
        },
        {
          path: "/reports/reportGrnSkuWise",
          name: "reports.reportGrnSkuWise",
          component: () =>
            import(
              /* webpackChunkName: "reportGrnSkuWise" */ "@/own/pages/reports/GrnSkuWise.vue"
            ),
        },
        {
          path: "/reports/reportpackingSortation",
          name: "reports.reportpackingSortation",
          component: () =>
            import(
              /* webpackChunkName: "reportpackingSortation" */ "@/own/pages/reports/PackingSortation.vue"
            ),
        },
        {
          path: "/reports/reportReadyCase",
          name: "reports.reportReadyCase",
          component: () =>
            import(
              /* webpackChunkName: "reportReadyCase" */ "@/own/pages/reports/ReadyCase.vue"
            ),
        },
        {
          path: "/reports/reportDispatchSummary",
          name: "reports.reportDispatchSummary",
          component: () =>
            import(
              /* webpackChunkName: "reportDispatchSummary" */ "@/own/pages/reports/DispatchSummary.vue"
            ),
        },
        {
          path: "/reports/reportDispatchDetail",
          name: "reports.reportDispatchDetail",
          component: () =>
            import(
              /* webpackChunkName: "reportDispatchDetail" */ "@/own/pages/reports/DispatchDetail.vue"
            ),
        },
        {
          path: "/reports/reportPendingReceiving",
          name: "reports.reportPendingReceiving",
          component: () =>
            import(
              /* webpackChunkName: "reportPendingReceiving" */ "@/own/pages/reports/PendingReceiving.vue"
            ),
        },
        {
          path: "/reports/reportInventoryBasedOnCase",
          name: "reports.reportInventoryBasedOnCase",
          component: () =>
            import(
              /* webpackChunkName: "reportInventoryBasedOnCase" */ "@/own/pages/reports/InventoryBasedOnCase.vue"
            ),
        },
        {
          path: "/reports/standardQc",
          name: "reports.standardQc",
          component: () =>
            import(
              /* webpackChunkName: "standardQc" */ "@/own/pages/reports/StandardQC.vue"
            ),
        },
        {
          path: "/reports/reportChute",
          name: "reports.reportChute",
          component: () =>
            import(
              /* webpackChunkName: "reportChute" */ "@/own/pages/reports/Chute.vue"
            ),
        },
        {
          path: "/reports/reportCasePriority",
          name: "reports.reportCasePriority",
          component: () =>
            import(
              /* webpackChunkName: "reportCasePriority" */ "@/own/pages/reports/CasePriority.vue"
            ),
        },
        {
          path: "/reports/reportSortedNotPackedReport",
          name: "reports.reportSortedNotPackedReport",
          component: () =>
            import(
              /* webpackChunkName: "reportSortedNotPackedReport" */ "@/own/pages/reports/SortedNotPackedReport.vue"
            ),
        },
        {
          path: "/reports/reportPackingSkuSortation",
          name: "reports.reportPackingSkuSortation",
          component: () =>
            import(
              /* webpackChunkName: "reportPackingSkuSortation" */ "@/own/pages/reports/SKUPackingSortation.vue"
            ),
        },
        {
          path: "/reports/reportSortationSku",
          name: "reports.reportSortationSku",
          component: () =>
            import(
              /* webpackChunkName: "SkuSortingDailyProgress" */ "@/own/pages/reports/SkuSortingDailyProgress.vue"
            ),
        },
        {
          path: "/reports/reportDynamic",
          name: "reports.reportDynamic",
          component: () =>
            import(
              /* webpackChunkName: "reportDynamic" */ "@/own/pages/reports/DynamicReport"
            ),
        },
        {
          path: "/reports/warehouseio",
          name: "reports.reportWarehouseInOut",
          component: () =>
            import(
              /* webpackChunkName: "warehouseio" */ "@/own/pages/reports/WarehouseIO"
            ),
        },
        {
          path: "/wes/reportException",
          name: "wes.reportException",
          component: () =>
            import(
              /* webpackChunkName: "reportException" */ "@/own/pages/wes/WesExceptionsReport"
            ),
        },
        {
          path: "/wes/reportSplChute",
          name: "wes.reportSplChute",
          component: () =>
            import(
              /* webpackChunkName: "reportSplChute" */ "@/own/pages/wes/WesSplChuteReport"
            ),
        },
        {
          path: "/wes/reportSortPlan",
          name: "wes.reportSortPlan",
          component: () =>
            import(
              /* webpackChunkName: "reportException" */ "@/own/pages/wes/WesSortPlanReport"
            ),
        },
        {
          path: "/wes/reportSplUserProductivity",
          name: "wes.reportSplUserProductivity",
          component: () =>
            import(
              /* webpackChunkName: "reportSplUserProductivity" */ "@/own/pages/wes/WesUserProductivityReport"
            ),
        },
        {
          path: "/wes/reportSplInductionProductivity",
          name: "wes.reportSplInductionProductivity",
          component: () =>
            import(
              /* webpackChunkName: "reportSplInductionProductivity" */ "@/own/pages/wes/WesSplInductionProductivity"
            ),
        },
        // customer services
        {
          path: "/call_assignments",
          name: "customer_service.call_assignments",
          component: () =>
            import(
              /* webpackChunkName: "call_assignments" */ "@/own/pages/customerService/callAssignment"
            ),
        },
        {
          path: "/call_sheets",
          name: "customer_service.call_sheets",
          component: () =>
            import(
              /* webpackChunkName: "call_sheets" */ "@/own/pages/customerService/callSheet"
            ),
        },

        {
          path: "/wesAccounts",
          name: "wes.wesAccounts",
          component: () =>
            import(
              /* webpackChunkName: "wesAccounts" */ "@/own/pages/wes/WESConfigurationAccount"
            ),
        },
        {
          path: "/wesExceptionReasons",
          name: "wes.wesExceptionReasons",
          component: () =>
            import(
              /* webpackChunkName: "wesExceptionReasons" */ "@/own/pages/wes/WESExceptionReasons"
            ),
        },
        {
          path: "/wesChutes",
          name: "wes.wesChutes",
          component: () =>
            import(
              /* webpackChunkName: "wesChutes" */ "@/own/pages/wes/WESConfigurationChutes"
            ),
        },
        {
          path: "/wesSortPlan",
          name: "wes.wesSortPlan",
          component: () =>
            import(
              /* webpackChunkName: "wesSortPlan" */ "@/own/pages/wes/WESSortPlan"
            ),
        },
        {
          path: "/wesStations",
          name: "wes.wesStations",
          component: () =>
            import(
              /* webpackChunkName: "wesStations" */ "@/own/pages/wes/WESConfigurationStations"
            ),
        },
        {
          path: "/wesBflContainers",
          name: "wes.wesBflContainers",
          component: () =>
            import(
              /* webpackChunkName: "wesBflContainers" */ "@/own/pages/wes/WESBFLContainers"
            ),
        },
        {
          path: "/wesBflSync",
          name: "wes.wesBflSync",
          component: () =>
            import(
              /* webpackChunkName: "wesBflSync" */ "@/own/pages/wes/WESBFLSync"
            ),
        },
        {
          path: "/wesSortingLocations",
          name: "wes.wesSortingLocations",
          component: () =>
            import(
              /* webpackChunkName: "wesSortingLocations" */ "@/own/pages/wes/WESSortingLocations"
            ),
        },
        {
          path: "/wesSplWaves",
          name: "wes.wesSplWaves",
          component: () =>
            import(
              /* webpackChunkName: "wesSortingLocations" */ "@/own/pages/wes/WesSplWaves"
            ),
        },
        {
          path: "/wesSplSortingItemConfiguration",
          name: "wes.wesSplSortingItemConfiguration",
          component: () =>
            import(
              /* webpackChunkName: "wesSortingLocations" */ "@/own/pages/wes/WesSplSortingItemConfiguration"
            ),
        },
        {
          path: "/wesSplTransactionMonitoring",
          name: "wes.wesSplTransactionMonitoring",
          component: () =>
            import(
              /* webpackChunkName: "wesSplTransactionMonitoring" */ "@/own/pages/wes/WESSPLTransactionMonitoring"
            ),
        },
        {
          path: "/wesSplMonitoring",
          name: "wes.wesSplMonitoring",
          component: () =>
            import(
              /* webpackChunkName: "wesSortingLocations" */ "@/own/pages/wes/WesSplMonitoring"
            ),
        },
        {
          path: "/wes/wesSplBarcodeConfiguration",
          name: "wes.wesSplBarcodeConfiguration",
          component: () =>
            import(
              /* webpackChunkName: "wesSortingLocations" */ "@/own/pages/wes/WesSplBarcodeConfiguration"
            ),
        },
        {
          path: "/wesSplMonitoring",
          name: "wes.wesSplMonitoring",
          component: () =>
            import(
              /* webpackChunkName: "wesSortingLocations" */ "@/own/pages/wes/WesSplMonitoring"
            ),
        },
      ],
    },
    {
      name: "invoiceTemp",
      path: "/invoice_temp",
      props: true,
      component: () =>
        import(/* webpackChunkName: "invoiceTemp" */ "@/own/pages/InvoiceTemp"),
    },
    // authentication start
    {
      name: "login",
      path: "/login",
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/view/pages/auth/login_pages/Login"
        ),
    },
    {
      name: "privacy_policy",
      path: "/privacy_policy",
      component: () =>
        import(
          /* webpackChunkName: "privacy_policy" */ "@/own/pages/PrivacyPolicy"
        ),
    },
    {
      name: "terms_and_conditions",
      path: "/terms_and_conditions",
      component: () =>
        import(
          /* webpackChunkName: "terms_and_conditions" */ "@/own/pages/TermsAndConditions"
        ),
    },
    {
      name: "loginNew",
      path: "/integration/login",
      component: () =>
        import(
          /* webpackChunkName: "integrationLogin" */ "@/view/pages/auth/login_pages/LoginNew"
        ),
    },
    {
      name: "registerNew",
      path: "/integration/register",
      component: () =>
        import(
          /* webpackChunkName: "integrationRegister" */ "@/view/pages/auth/RegisterNew"
        ),
    },
    {
      path: "/",
      component: () => import("@/view/layout/auth/GeneratePasswordLayout"),
      children: [
        {
          name: "forgot_password",
          path: "/forgot_password",
          component: () =>
            import(
              /* webpackChunkName: "forgotPassword" */ "@/own/pages/auth/ForgotPassword"
            ),
        },
        {
          name: "password_reset",
          path: "/password_reset",
          component: () =>
            import(
              /* webpackChunkName: "passwordReset" */ "@/own/pages/auth/PasswordReset"
            ),
        },
      ],
    },
    // authentication end
    {
      name: "maintenance",
      path: "/maintenance",
      props: true,
      component: () =>
        import(/* webpackChunkName: "maintenance" */ "@/own/pages/Maintenance"),
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () =>
        import(/* webpackChunkName: "404" */ "@/view/pages/error/Error-1.vue"),
    },
  ],
  mode: "history",
});
